export const dspCampaignMixin = {
  props: {
    dsp_campaign: {
      type: Object,
    },
    log: {
      type: Array,
    },
    google_target_locations: {
      type: Array,
    },
    campaign_planning_breakdowns: {
      type: Object,
    },
    stats: {
      type: Array,
    },
    campaign_statistics: {},
  },

  data: () => ({
    retargeting: false,
    retargeting_campaigns: [],
    retargeting_type: 'previous_campaigns',
    valid: true,
    weight: 1,
    isLead: false,
    isEngagement: false,
    selected_regions: [],
    selected_regions_string: "",
    selected_exchanges: ["Double Click"],
    selected_pretargets: {},
    query_params: "",
    selected_pretarget_id: null,
    appCampaign: false,
    selected_rules: [],
    rules_ids: [],
    selected_google_pretargets: null,
    selected_google_pretarget_id: null,
    selected_prebid_viewability: "70",
    location: "Kenya",
    status: "offline",
    isSSP: null,
    statistics: {},
    isValid: true,
    click_through_URL: "",
    advertiser: "",
    selected_advertiser_id: null,
    branding_type: "adchoices",
    campaignType: null,
    campaignObjective: null,
    selected_campaign_type: "awareness",
    campaignName: "",
    pricingModel: "CPM",
    CeCPM: null,
    CeCPC: 0.2,
    BeCPM: 0.3,
    billing_id: null,
    totalBudget: null,
    daily_budget: null,
    bidding_budget: null,
    hourly_budget: null,
    impressions: null,
    time_format: "minutes",
    frequency_spec: "device.ip",
    frequency_expire_after: null,
    frequency_count: null,
    time: [],
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) //add 24 hours or 1day for default end date
      .toISOString()
      .substr(0, 10),
    banner_branding: true,
    clicks: null,
    conversions: null,
    cascade_click_url: true,
    campaign_data: {},
    menu1: false,
    menu2: false,
    campaign_objectives: [
      {
        name: "Brand Awareness",
        value: "awareness",
        campaign_types: [
          { name: "Display", value: "display" },
          { name: "Video", value: "video" },
          { name: "Engagement", value: "engagement" },
        ],
      },
      {
        name: "Reach",
        value: "reach",
        campaign_types: [
          { name: "Display", value: "display" },
          { name: "Video", value: "video" },
          { name: "Engagement", value: "engagement" },
        ],
      },
      {
        name: "Traffic",
        value: "traffic",
        campaign_types: [
          { name: "Display", value: "display" },
          { name: "Video", value: "video" },
        ],
      },
      {
        name: "Conversion",
        value: "conversion",
        campaign_types: [{ name: "Display", value: "display" }],
      },
      {
        name: "Leads",
        value: "leads",
        campaign_types: [{ name: "Display", value: "display" }],
      },
      {
        name: "Local Store Visits",
        value: "store_visits",
        campaign_types: [
          { name: "Display", value: "display" },
          { name: "Video", value: "video" },
        ],
      },
      {
        name: "Sales",
        value: "sales",
        campaign_types: [
          { name: "Display", value: "display" },
          { name: "Video", value: "video" },
          { name: "Shopping", value: "shopping" },
        ],
      },
      {
        name: "Product Consideration",
        value: "product_consideration",
        campaign_types: [{ name: "Video", value: "video" }],
      },
      {
        name: "App Installs/Acquisition",
        value: "installs",
        campaign_types: [{ name: "App", value: "app" }],
      },
      {
        name: "No Goal",
        value: "no_goal",
        campaign_types: [{ name: "All Types Apply", value: "all_types" }],
      },
    ],
    campaign_types: [
      {
        name: "Display Campaign",
        value: "display",
        campaign_objectives: [
          { name: "Awareness", value: "awareness" },
          { name: "Reach", value: "reach" },
          { name: "Traffic", value: "traffic" },
          { name: "Leads", value: "leads" },
        ],
      },
      {
        name: "Video Campaign",
        value: "video",
        campaign_objectives: [
          { name: "Video Views", value: "video_views" },
          { name: "Engagement", value: "engagement" },
        ],
      },
      {
        name: "App Campaign",
        value: "app",
        campaign_objectives: [{ name: "App Installs", value: "app_installs" }],
      },
    ],
    pricing_models: [
      "CPM",
      "CPC",
      "CPL",
      "CPV",
      "CPE",
      "CPA",
      "CPI",
      "Tenancy",
    ],
    regions: [
      { code: "US", name: "United States" },
      { code: "AP", name: "Asia Pacific" },
      { code: "EU", name: "Europe" },
      { code: "NL", name: "Netherlands" },
      { code: "RU", name: "Russia" },
      // {code:"AFR", name: "Africa"}
    ],
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    running_time: null,
    start: null,
    end: null,
    running_times: [],
    starttime: false,
    endtime: false,
    modal2: false,
    campaign_start_times: null,
    campaign_stop_times: null,
    exchanges: [
      "AdColony",
      "Adswizz",
      "Android tv",
      "Anzu",
      "AppNexus",
      "Baidu",
      "Bidstack",
      "Bidswitch",
      "Chartboost",
      "Digital Turbine",
      "Discovery",
      "Double Click",
      "Equative",
      "Free Wheel",
      "Geniee",
      "Gum Gum",
      "Hivestack",
      "Huawei Ads",
      "imo",
      "In Mobi",
      "Index Exchange",
      "LG",
      "LoopMe",
      "Magnite",
      "Mediapal SSP",
      "Microsoft Ad Exchange",
      "Mobfox",
      "Mobvista",
      "MoPub",
      "OpenX",
      "Opera",
      "Outbrain",
      "Perion",
      "Phoenix",
      "Pollfish",
      "PubMatic",
      "Right Media (Yahoo)",
      "Rubicon Project",
      "Samsung Ads",
      "Smaato",
      "Smart Ad Server",
      "SmartyAds",
      "Supersonic",
      "Taboola",
      "Tapjoy",
      "Teads tv",
      "Transsion",
      "Truecaller",
      "Verizon Media",
      "Vungle",
      "Xandr",
      "Xiaomi",
      "YAHOO",
    ],
    pre_bid_viewability: [
      "0",
      "10",
      "20",
      "30",
      "40",
      "50",
      "60",
      "70",
      "80",
      "90",
    ],
    row: "immediately",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    error: null,
    dialog: false,
    addNewTaget: false,
    campImpressions: 0,
    campCPL: 0,
    campClicks: 0,
    campCTR: 0,
    campSpend: 0,
    campCPC: 0,
    campCPM: 0,
    campLeads: 0,
    campViews: 0,
    campVTR: 0,
    targetsLoading: false,
    pretargetsLoading: false,
  }),
  watch: {
    async selected_regions() {
      this.selected_regions_string = this.selected_regions.length
        ? await this.selected_regions.map((region) => region.code).toString()
        : null;
    },
    selected_google_pretargets() {
      if (this.selected_google_pretargets != null) {
        this.selected_google_pretarget_id = this.selected_google_pretargets.id;
        this.billing_id = this.selected_google_pretargets.billingId;
      }
    },
    selected_pretargets() {
      this.selected_pretarget_id = this.selected_pretargets
        ? this.selected_pretargets.id
        : null;
    },
    selected_rules() {
      this.rules_ids = this.selected_rules.map((rule) => rule.id);
    },
    advertiser() {
      this.selected_advertiser_id = this.advertiser ? this.advertiser.id : null;
    },
    campaignType() {
      this.selected_campaign_type = this.campaignType
        ? this.campaignType.value
        : "";
    },
    CeCPM() {
      this.bidding_budget = (this.BeCPM / this.CeCPM) * this.totalBudget;
      const total = (this.totalBudget / this.CeCPM) * 1000;
      this.impressions = Math.trunc(total);
    },
    BeCPM() {
      this.bidding_budget = (this.BeCPM / this.CeCPM) * this.totalBudget;
    },
    totalBudget() {
      this.bidding_budget = (this.BeCPM / this.CeCPM) * this.totalBudget;
      const total = (this.totalBudget / this.CeCPM) * 1000;
      this.impressions = Math.trunc(total);
      this.daily_budget =
        this.totalBudget / this.datediff(this.startDate, this.endDate);
    },
    async dsp_campaign() {
      this.populateFormOnEdit(await this.dsp_campaign);
      //Editing logic
    },
    dsp_campaign_rules() {
      if (this.$route.name === "edit-dsp-campaign") {
        const selected_rule_ids = this.dsp_campaign?.rtb_standards
          ? this.dsp_campaign?.rtb_standards?.map((rule) => rule.id)
          : [];
        this.selected_rules = this.dsp_campaign_rules?.filter((rule) =>
          selected_rule_ids.includes(rule.id)
        );
      }
    },

    startDate() {
      this.daily_budget =
        this.totalBudget / this.datediff(this.startDate, this.endDate);
    },
    endDate() {
      this.daily_budget =
        this.totalBudget / this.datediff(this.startDate, this.endDate);
    },

    campaign_statistics() {
      this.getCampaignstats();
      this.isLead =
        this.campaign_statistics.campaign.campaign_attribute
          .campaign_objective === "conversion";
      this.isEngagement =
        this.campaign_statistics.campaign.campaign_attribute.campaign_type ===
        "engagement";
      console.log(this.isEngagement);
    },
    dsp_targets() {
      if (this.dsp_campaign && this.$route.name === "edit-dsp-campaign") {
        this.selected_pretargets = this.dsp_targets?.find(
          (target) => target.id === this.dsp_campaign.target_id
        );
      }
    },
    google_pretargets(){
      if (this.dsp_campaign && this.$route.name === "edit-dsp-campaign") {
        this.selected_google_pretargets = this.google_pretargets.find(
          (target) => {
            return (
              target.billingId.trim() ===
              this.dsp_campaign?.campaign_attribute?.billing_id
            );
          }
        );
      }
    }
  },
  mounted() {
    if (
      this.$route.name === "new-opera-campaign" ||
      this.$route.name === "new-transsion-campaign" ||
      this.$route.name === "new-truecaller-campaign" ||
      this.$route.name === "new-phoenix-campaign" ||
      this.$route.name === "new-ctv-campaign" ||
      this.$route.name === "new-gaming-campaign" 
    ) {
      this.appCampaign = true;
    }
  },
  async created() {
    //Advertisers
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getAllAdvertisers");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }
    //Campaign Rules
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getAllCampaignRules");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }
    //Targets
    this.fetchTargets();
    //Google Pretargets
    try {
      if (this.$route.name === "edit-dsp-campaign") {
        this.pretargetsLoading = true;
        await this.$store.dispatch("dsp/getPretargetingConfigs", true);

      } else if (this.$route.name === "new-campaign") {
        await this.$store.dispatch("dsp/getPretargetingConfigs");
      }
    } catch (error) {
      this.error = error;
    } finally {
      this.pretargetsLoading = false;
    }

    //Fetch Campaigns
    this.getCampaigns();
  },
  computed: {
    all_campaigns() {
      return this.$store.state.dsp.dsp_campaigns;
    },
    _todaysDate() {
      const date = new Date();
      return date.toLocaleDateString("en-CA"); // "yyyy-mm-dd"
    },
    dsp_advertisers() {
      return this.$store.state.dsp.dsp_advertisers;
    },
    viewMode() {
      return this.$route.name === "view-dsp-campaign";
    },
    dsp_campaign_rules() {
      return this.$store.state.dsp.dsp_campaign_rules;
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    dsp_targets() {
      return this.$store.state.dsp.dsp_targets;
    },
    google_pretargets() {
      return this.$store.state.dsp.pretargeting_configs
        ? this.$store.state.dsp.pretargeting_configs
        : [];
    },
    //determine if its programmatic or app campaigns
    __channel() {
      const currentRoute = this.$route.name;

      if (currentRoute === "new-campaign") {
        return "programmatic";
      }
      if (currentRoute === "new-opera-campaign") {
        return "opera";
      }
      if (currentRoute === "new-truecaller-campaign") {
        return "truecaller";
      }
      if (currentRoute === "new-transsion-campaign") {
        return "transsion";
      }
      if (currentRoute === "new-phoenix-campaign") {
        return "phoenix";
      }
      if (currentRoute === "new-ctv-campaign") {
        return "ctv";
      }
      if (currentRoute === "new-gaming-campaign") {
        return "gaming";
      }
    },
  },
  methods: {
    async getCampaigns() {
      try {
        await this.$store.dispatch("dsp/getAllDspCampaigns");
      } catch (error) {
        console.log(error);
      }
    },
    async createTarget(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/createTargetRule", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Target created successfully!",
          group: "success",
        });
        this.addNewTaget = false;
        this.fetchTargets();
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    convertFrequencyToHours(frequency) {
      //convert seconds to hours
      return frequency / 3600;
    },

    //convert hours to seconds
    convertHoursToSeconds(hours) {
      //check if hours is not null
      if (!hours) {
        return 0;
      }
      return hours * 3600;
    },

    async fetchTargets() {

      this.targetsLoading = true;

      if (
        this.$route.name === "new-opera-campaign" ||
        this.$route.name === "new-transsion-campaign" ||
        this.$route.name === "new-truecaller-campaign" ||
        this.$route.name === "new-phoenix-campaign" ||
        this.$route.name === "new-ctv-campaign" ||
        this.$route.name === "new-gaming-campaign" 
      ) {

        let app = this.$route.name.split("new-")[1].split("-campaign")[0];

        //Campaigns
        try {
          await this.$store.dispatch("dsp/getAllAppCampaignTargets", app);
          
        } catch (error) {
          this.error = error;
        } finally {
          this.targetsLoading = false;
        }
      } else {
        try {
          await this.$store.dispatch("dsp/getAllTargets");
        } catch (error) {
          this.error = error;
        } finally {
          this.targetsLoading = false;
        }
      }
    },

    eventTime(item) {
      const d = new Date(item);

      let time = d.toDateString();

      return time;
    },

    getCampaignstats() {
      this.campImpressions = this.campaign_statistics.total_impressions
        ? this.campaign_statistics.total_impressions
        : 0;
      this.campCPL = this.campaign_statistics.cpl
        ? this.campaign_statistics.cpl
        : 0;
      this.campLeads = this.campaign_statistics.campaign.leads_count
        ? this.campaign_statistics.campaign.leads_count
        : 0;
      this.campClicks = this.campaign_statistics.total_clicks
        ? this.campaign_statistics.total_clicks
        : 0;
      this.campCTR = this.campaign_statistics.ctr
        ? this.campaign_statistics.ctr.toFixed(2)
        : 0;
      this.campSpend = this.campaign_statistics.campaign_spend
        ? this.campaign_statistics.campaign_spend.toFixed(2)
        : 0;
      this.campCPC = this.campaign_statistics.cpc
        ? this.campaign_statistics.cpc.toFixed(2)
        : 0;
      this.campCPM = this.campaign_statistics.cpm
        ? this.campaign_statistics.cpm.toFixed(2)
        : 0;
      this.campViews = this.campaign_statistics.views
        ? this.campaign_statistics.views
        : 0;
      this.campVTR = this.campaign_statistics.vtr
        ? this.campaign_statistics?.toFixed(2)
        : 0;
    },

    datediff(first, second) {
      var date1 = new Date(first);
      var date2 = new Date(second);

      var Difference_In_Time = date2.getTime() - date1.getTime();

      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      return Difference_In_Days;

      // return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },

    logChange(item) {
      const old = item.properties.oldValues;
      const updated = item.properties.oldValues;

      old.forEach((num1, index) => {
        const num2 = updated[index];
        return num1, num2;
      });
    },

    isDisabled(values) {
      return values.start != null && values.end != null ? false : true;
    },
    addRunningTime() {
      this.running_times.push([this.start, this.end]);

      this.campaign_start_times = this.running_times.length
        ? this.running_times.map((x) => x[0]).toString()
        : null;

      this.campaign_stop_times = this.running_times.length
        ? this.running_times.map((x) => x[1]).toString()
        : null;

      this.start = null;
      this.end = null;
    },
    deleteRunningTime(key) {
      if (key > -1) {
        this.running_times.splice(key, 1);
        //after splicing update the stop times and start times
        this.campaign_start_times = this.running_times.length
          ? this.running_times.map((x) => x[0]).toString()
          : null;

        this.campaign_stop_times = this.running_times.length
          ? this.running_times.map((x) => x[1]).toString()
          : null;
      }
    },
    //Populate form on edit
    populateFormOnEdit() {
      if (
        this.$route.name == "edit-dsp-campaign" ||
        (this.$route.name == "view-dsp-campaign" &&
          this.dsp_campaign &&
          this.dsp_advertisers &&
          this.dsp_campaign_rules)
      ) {
        let start_date = this.dsp_campaign.activate_time.replace(/-/g, "/"); //replace() fixes date format for safari
        start_date = start_date
          ? new Date(
              this.dsp_campaign.activate_time.replace(/-/g, "/")
            ).toLocaleDateString("en-ca")
          : null;

        let end_date = this.dsp_campaign.expire_time.replace(/-/g, "/"); //replace() fixes date format for safari15.6;
        end_date = end_date
          ? new Date(
              this.dsp_campaign.expire_time.replace(/-/g, "/")
            ).toLocaleDateString("en-ca")
          : null;

        this.campaignName = this.dsp_campaign.name;

        this.advertiser = this.dsp_advertisers.find(
          (advertiser) =>
            advertiser.id == this.dsp_campaign.campaign_attribute.client_id
        );
        this.click_through_URL = this.dsp_campaign.ad_domain;
        this.pricingModel = this.dsp_campaign.campaign_attribute.conversion_type
          ? this.dsp_campaign.campaign_attribute.conversion_type.toLowerCase()
          : this.pricingModel;
        this.CeCPM = this.dsp_campaign.campaign_attribute.campaign_ecpm;
        this.CeCPC = this.dsp_campaign.campaign_attribute.campaign_ecpc;
        this.BeCPM = this.dsp_campaign.campaign_attribute.bid_ecpm;
        this.totalBudget = parseFloat(this.dsp_campaign.total_budget).toFixed(
          2
        );
        this.daily_budget = parseFloat(
          this.dsp_campaign.budget_limit_daily
        ).toFixed(2);
        this.hourly_budget = parseFloat(
          this.dsp_campaign.budget_limit_hourly
        ).toFixed(2);
        this.impressions = parseInt(
          this.dsp_campaign.campaign_attribute.impressions
        );
        this.row = "specificDate";
        this.startDate = start_date;
        this.endDate = end_date;

        //allow multiple to be returned
        const exchanges = this.dsp_campaign.exchanges;
        const exchangesArray = exchanges.split(",");
        const newEchangesArray = exchangesArray.map((item) =>
          item === "google" ? "Double Click" : item
        );
        this.selected_exchanges = newEchangesArray;

        this.campaignType = this.findNestedObject(
          this.dsp_campaign.campaign_attribute.campaign_type,
          this.campaign_objectives
        );
        this.campaignObjective = this.campaign_objectives.find(
          (objective) =>
            objective.value ==
            this.dsp_campaign.campaign_attribute.campaign_objective
        );

        this.frequency_spec =
          this.dsp_campaign.campaign_attribute.frequency_spec;
        this.frequency_expire_after = this.convertFrequencyToHours(
          this.dsp_campaign.campaign_attribute.frequency_expire
        );
        this.frequency_count =
          this.dsp_campaign.campaign_attribute.frequency_count;

        this.cascade_click_url = Boolean(
          this.dsp_campaign?.campaign_attribute?.cascade_click_url
        );

        this.banner_branding =
          this.dsp_campaign.campaign_attribute.add_banner_branding;

        this.selected_google_pretargets = this.google_pretargets.find(
          (target) => {
            return (
              target.billingId.trim() ===
              this.dsp_campaign.campaign_attribute.billing_id
            );
          }
        );

        this.weight = this.dsp_campaign.weight;

        this.branding_type = this.dsp_campaign.campaign_attribute.branding_type
          ? this.dsp_campaign.campaign_attribute.branding_type.toString()
          : "";

        this.location = this.dsp_campaign.campaign_attribute.locations
          ? this.dsp_campaign.campaign_attribute.locations.toString()
          : "";

        this.selected_prebid_viewability = this.dsp_campaign.campaign_attribute
          .pre_bid_viewability
          ? this.dsp_campaign.campaign_attribute.pre_bid_viewability.toString()
          : this.dsp_campaign.campaign_attribute.pre_bid_viewability;

        if (
          this.dsp_campaign.status &&
          this.dsp_campaign.status === "runnable"
        ) {
          this.status = "runnable";
        } else {
          this.status = "offline";
        }

        this.forensiq = this.dsp_campaign.forensiq
          ? this.dsp_campaign.forensiq.toString()
          : "";

        const mediapal = this.dsp_campaign.regions
          ? this.dsp_campaign.regions.split(",")
          : [];
        this.selected_regions = this.regions.filter((region) =>
          mediapal.includes(region.code)
        );

        const start_times = this.dsp_campaign.campaign_attribute
          .campaign_start_times
          ? this.dsp_campaign.campaign_attribute.campaign_start_times.split(",")
          : [];
        const stop_times = this.dsp_campaign.campaign_attribute
          .campaign_stop_times
          ? this.dsp_campaign.campaign_attribute.campaign_stop_times.split(",")
          : [];

        const finalArray = [];

        start_times.forEach((i, index) => {
          finalArray.push([i, stop_times[index]]);
        });

        this.running_times = finalArray;

        this.campaign_start_times = this.running_times.length
          ? this.running_times.map((x) => x[0]).toString()
          : [];

        this.campaign_stop_times = this.running_times.length
          ? this.running_times.map((x) => x[1]).toString()
          : [];
      }

      if (
        (this.dsp_campaign && this.$route.name === "edit-dsp-campaign") ||
        this.$route.name === "view-dsp-campaign"
      ) {
        this.selected_pretargets = this.dsp_targets.find(
          (target) => target.id === this.dsp_campaign.target_id
        );
      }
    },
    findNestedObject(search_item, array) {
      let found;
      for (const item of array) {
        found = item.campaign_types.find((item) => item.value == search_item);
        if (found) break;
      }
      return found;
    },

    //Asign a value to campaign start immediately
    campaignStart() {
      if (this.row === "immediately") {
        this.startDate = new Date().toISOString().split("T")[0];
      }
    },

    async next() {
      this.isValid = !this.isValid;
      await this.$refs.form.validate();
      if (!this.valid) return;
      if (this.$route.name === "new-opera-campaign") {
        this.rules_ids = [14];
      }
      if (this.$route.name === "new-transsion-campaign") {
        this.rules_ids = [64];
      }
      if (this.$route.name === "new-phoenix-campaign") {
        this.rules_ids = [69];
      }
      if (this.$route.name === "new-truecaller-campaign") {
        this.rules_ids = [67];
      }
      if (this.$route.name === "new-ctv-campaign") {
        this.rules_ids = [76];
      }
      if (this.$route.name === "new-gaming-campaign") {
        this.rules_ids = [82]; 
      }

      const req_body = {
        customer_id: "1",
        name: this.campaignName,
        activate_time: this.startDate,
        expire_time: this.endDate,
        total_budget: this.totalBudget,
        budget_limit_daily: this.daily_budget,
        budget_limit_hourly: this.hourly_budget,
        bid: this.bidding_budget,
        clicks: this.clicks,
        weight: this.weight,
        ad_domain: this.click_through_URL,
        regions: this.selected_regions_string,
        exchanges: this.selected_exchanges.join(),
        target_id: this.selected_pretarget_id,
        rtb_standards: this.rules_ids,
        restricted_category: this.selected_restricted_categories,
        status: this.status,
        forensiq: this.forensiq,
        campaign_attributes: {
          conversion_type: this.pricingModel.toUpperCase(),
          client_id: this.selected_advertiser_id,
          bid_ecpm: this.BeCPM,
          locations: this.location,
          channel: this.__channel,
          campaign_ecpm: this.CeCPM,
          campaign_ecpc: this.CeCPC,
          campaign_type: this.selected_campaign_type,
          campaign_objective: this.campaignObjective.value,
          add_banner_branding: this.banner_branding,
          branding_type: this.branding_type,
          impressions: this.impressions,
          campaign_start_times: this.campaign_start_times,
          campaign_stop_times: this.campaign_stop_times,
          cascade_click_url: this.cascade_click_url,
          billing_id: this.billing_id,
          pre_bid_viewability: this.selected_prebid_viewability,
          frequency_spec: this.frequency_spec,
          frequency_expire: this.convertHoursToSeconds(
            this.frequency_expire_after
          ),
          frequency_count: this.frequency_count,
        },
      };

      if (this.$route.name === "edit-dsp-campaign") {
        this.$emit("emitCampaignBodyUpdate", req_body);
      } else if (
        this.$route.name === "new-campaign" ||
        this.$route.name === "new-opera-campaign" ||
        this.$route.name === "new-truecaller-campaign" ||
        this.$route.name === "new-phoenix-campaign" ||
        this.$route.name === "new-transsion-campaign" ||
        this.$route.name === "new-ctv-campaign" ||
        this.$route.name === "new-gaming-campaign"
      ) {
        this.$emit("emitCampaignBodyCreate", req_body);
      }
    },
    navigatetoImpressions() {
      this.$router.push({
        name: "campaign-planning",
      });
    },
    getLocation(id) {
      this.$emit("getLocationName", id);
    },
  },
  beforeDestroy() {
    this.$refs.form.reset();
  },
};
