<template>
  <div>
    <v-form lazy-validation v-model="valid" ref="form" class="campaign-form">
      <v-row>
        <v-col cols="7">
          <!-- Campaign -->
          <v-card flat class="mb-5">
            <v-card-title class="py-2">
              <h5>Campaign Setup</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="flex-column" dense>
                <v-col>
                  <h5 class="mb-1">Advertiser</h5>
                  <v-select
                    :readonly="viewMode"
                    :items="dsp_advertisers"
                    outlined
                    dense
                    item-text="client_name"
                    v-model="advertiser"
                    return-object
                    label="Select advertiser*"
                    required
                    :rules="[(v) => !!v || 'Advertiser is required!']"
                    placeholder="Select Advertiser"
                    :menu-props="{ bottom: true, offsetY: true }"
                    hide-details="auto"
                    single-line
                  ></v-select>
                </v-col>
                <v-col>
                  <h5 class="mb-1">Campaign Name</h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="campaignName"
                    required
                    :rules="[(v) => !!v || 'Campaign name is required!']"
                    label="Campaign name *"
                    outlined
                    dense
                    placeholder="Campaign Name"
                    hide-details="auto"
                    single-line
                  ></v-text-field>
                </v-col>
                <v-col>
                  <h5 class="mb-1">Campaign Objective</h5>
                  <v-select
                    :readonly="viewMode"
                    :items="campaign_objectives"
                    item-text="name"
                    outlined
                    return-object
                    dense
                    v-model="campaignObjective"
                    label="Select Campaign Objective *"
                    required
                    :rules="[
                      (v) => !!v || 'Select campaign Objective to continue!',
                    ]"
                    placeholder="Select Campaign Objective"
                    :menu-props="{ bottom: true, offsetY: true }"
                    hide-details="auto"
                    single-line
                  ></v-select>
                </v-col>
                <v-col>
                  <h5 class="mb-1">Campaign Type</h5>
                  <v-select
                    :readonly="viewMode"
                    :items="
                      this.campaignObjective
                        ? this.campaignObjective.campaign_types
                        : []
                    "
                    item-text="name"
                    outlined
                    return-object
                    dense
                    v-model="campaignType"
                    label="Select Campaign Type *"
                    required
                    :rules="[
                      (v) => !!v || 'Select a campaign objective and type',
                    ]"
                    placeholder="Select Campaign Type"
                    :menu-props="{ bottom: true, offsetY: true }"
                    hide-details="auto"
                    single-line
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Banner Branding -->
          <v-card flat class="mb-5">
            <v-card-title class="py-2">
              <h5>Click Through URL</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row dense class="flex-column">
                <v-col>
                  <h5 class="mb-1">Click Through URL</h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="click_through_URL"
                    label="Click Through URL"
                    outlined
                    dense
                    placeholder="e.g. https://example.domain?product=123"
                    persistent-placeholder
                    single-line
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-checkbox v-model="cascade_click_url">
                    <template v-slot:label>
                      <div>Apply click through url to all banners</div>
                    </template>
                  </v-checkbox>

                  <v-alert
                    text
                    dense
                    color="teal"
                    icon="mdi-information-outline"
                    border="left"
                    class="mb-0"
                  >
                    {{
                      cascade_click_url
                        ? "Click through URL on banners will be set by campaign"
                        : "Each banner will have individual Click through URLs'"
                    }}
                  </v-alert>
                  <!-- <h5>Cascade Click URL {{ cascade_click_url }}</h5>
                  <v-radio-group
                    :readonly="viewMode"
                    v-model="cascade_click_url"
                    class="mt-0"
                    dense
                    row
                    hide-details
                  >
                    <v-radio label="Yes" value="1"> </v-radio>
                    <v-radio
                      label="Use Individual Landing Pages On Banners"
                      value="0"
                    >
                    </v-radio>
                  </v-radio-group> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Campaign duration and running time -->

          <v-card flat class="mb-5">
            <v-card-title class="py-2">
              <h5>Campaign Duration and Running times</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="flex-column" dense>
                <v-col>
                  <h5>Start Campaign</h5>
                  <v-radio-group
                    :readonly="viewMode"
                    v-model="row"
                    @change="campaignStart"
                    dense
                    row
                    hide-details
                    class="mt-0"
                  >
                    <v-radio label="Immediately" value="immediately"> </v-radio>
                    <v-radio label="On a specific date" value="specificDate">
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col id="calendar">
                  <v-row>
                    <v-col>
                      <h5 class="mb-1">Start Date</h5>
                      <h5 class="mb-1" v-if="row === 'immediately'">
                        Your campaign starts today
                        {{ new Date().toLocaleDateString() }}
                      </h5>
                      <transition name="slide-fade">
                        <v-row v-if="row === 'specificDate'">
                          <v-col cols="12">
                            <v-menu
                              :readonly="viewMode"
                              v-model="menu1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="_formattedDates.start"
                                  label="Select Campaign Start Date"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                  hide-details="auto"
                                  prepend-inner-icon="mdi-calendar"
                                  single-line
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="startDate"
                                @input="menu1 = false"
                                locale="en-us"
                                :min="minDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </transition>
                    </v-col>
                    <v-col>
                      <h5 class="mb-1">End Date</h5>

                      <v-menu
                        :readonly="viewMode"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="_formattedDates.end"
                            label="Select campaign end date"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            hide-details="auto"
                            prepend-inner-icon="mdi-calendar"
                            single-line
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          @input="menu2 = false"
                          :min="minDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card flat class="mb-5">
            <v-card-title class="py-2">
              <h5>Campaign Pricing</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="flex-column" dense>
                <v-col>
                  <h5 class="mb-1">Pricing Model</h5>
                  <v-select
                    :readonly="viewMode"
                    :items="bidOptions"
                    :rules="[
                      (v) =>
                        !!v ||
                        'A pricing model is required!, select an objective and type to generate options',
                    ]"
                    item-text="name"
                    item-value="value"
                    label="Pricing Model"
                    outlined
                    dense
                    single-line
                    v-model="pricingModel"
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select> </v-col
                ><v-col>
                  <h5 class="mb-1">Total Budget</h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="totalBudget"
                    label="Total Budget"
                    outlined
                    dense
                    type="number"
                    :rules="[(v) => !!v || 'This field is required!']"
                    placeholder="$0.00"
                    persistent-placeholder
                    single-line
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="
                    campaignObjective && campaignObjective.value != 'traffic'
                  "
                >
                  <h5 class="mb-1">
                    Campaign e{{ pricingModel ? pricingModel : "CPM" }}
                  </h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="CeCPM"
                    single-line
                    label="Campaign Ecpm"
                    outlined
                    dense
                    type="number"
                    :rules="[(v) => !!v || 'This field is required!']"
                    placeholder="$0.00"
                    persistent-placeholder
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col v-else>
                  <h5 class="mb-1">Campaign eCPC</h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="CeCPC"
                    single-line
                    label="Campaign eCPC"
                    outlined
                    dense
                    type="number"
                    :rules="[(v) => !!v || 'This field is required!']"
                    placeholder="$0.00"
                    persistent-placeholder
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col v-if="superAdmin">
                  <h5 class="mb-1">Daily Budget</h5>
                  <v-text-field
                    :disabled="true"
                    v-model="daily_budget"
                    label="Daily Budget Limit"
                    outlined
                    dense
                    type="number"
                    placeholder="$0.00"
                    persistent-placeholder
                    hide-details="auto"
                    single-line
                  ></v-text-field>
                </v-col>

                <v-col v-if="false">
                  <h5 class="mb-1">Hourly Budget</h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="hourly_budget"
                    label="Hourly Budget Limit"
                    outlined
                    dense
                    type="number"
                    placeholder="$0.00"
                    persistent-placeholder
                    single-line
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col v-if="pricingModel && pricingModel == 'CPA'">
                  <h5 class="mb-1">Clicks</h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="clicks"
                    label="Clicks"
                    outlined
                    dense
                    type="number"
                    placeholder="0"
                    persistent-placeholder
                    single-line
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="
                    (pricingModel && pricingModel == 'CPM') ||
                    pricingModel == 'CPC' ||
                    pricingModel == 'CPA'
                  "
                >
                  <h5 class="mb-1">Impressions</h5>

                  <v-text-field
                    :readonly="viewMode"
                    disabled
                    v-model="impressions"
                    hide-details="auto"
                    label="Impressions"
                    outlined
                    dense
                    type="number"
                    placeholder="0"
                    persistent-placeholder
                    single-line
                    hide-detial
                  ></v-text-field>
                </v-col>
                <v-col v-if="pricingModel && pricingModel == 'CPA'">
                  <h5 class="mb-1">Conversions</h5>
                  <v-text-field
                    :readonly="viewMode"
                    v-model="conversions"
                    outlined
                    label="Conversions"
                    dense
                    single-line
                    type="number"
                    placeholder="0"
                    persistent-placeholder
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card flat class="mb-5">
            <v-card-title class="py-2">
              <h5>Campaign Targeting Options</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="flex-column" dense>
                <v-col>
                  <h5 class="mb-1">Location</h5>
                  <v-select
                    :items="countries"
                    outlined
                    :readonly="viewMode === true"
                    dense
                    small-chips
                    item-text="name"
                    return-object
                    label="Select Pretargets"
                    v-model="location"
                    single-line
                    hide-details="auto"
                    deletable-chips
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col>
                  <h5 class="mb-1">Exchanges</h5>
                  <v-select
                    :readonly="viewMode"
                    :items="exchanges"
                    outlined
                    multiple
                    dense
                    label="Choose Exchange(s)"
                    deletable-chips
                    small-chips
                    v-model="selected_exchanges"
                    single-line
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col>
                  <div class="d-flex align-center justify-space-between py-2">
                    <h5 class="mb-1">Pre Targets</h5>
                    <v-btn
                      v-if="this.$route.name === 'new-campaign'"
                      @click="addNewTaget = true"
                      depressed
                      color="primary"
                    >
                      <v-icon left>mdi-plus</v-icon> Create Pretarget</v-btn
                    >
                  </div>

                  <v-select
                    :loading="targetsLoading"
                    required
                    :items="dsp_targets"
                    outlined
                    deletable-chips
                    small-chips
                    :readonly="viewMode === true ? true : false"
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-text="name"
                    return-object
                    label="Select pretarget"
                    v-model="selected_pretargets"
                    single-line
                    hide-details="auto"
                  ></v-select>
                </v-col>

                <v-col>
                  <h5 class="mb-1">Viewability (%)</h5>
                  <v-select
                    :readonly="viewMode"
                    :items="pre_bid_viewability"
                    outlined
                    dense
                    label="Select pre-bid viewability"
                    v-model="selected_prebid_viewability"
                    single-line
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col v-if="this.$route.name == 'edit-dsp-campaign'">
                  <h5 class="mb-1">Status</h5>
                  <v-radio-group
                    v-model="status"
                    dense
                    row
                    hide-details
                    class="mt-0"
                  >
                    <v-radio
                      label="Runnable"
                      color="success"
                      value="runnable"
                    ></v-radio>
                    <v-radio
                      label="Offline"
                      color="secondary"
                      value="offline"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="!superAdmin">
              <v-row>
                <v-col
                  v-if="
                    this.$route.name == 'new-campaign' ||
                    this.$route.name === 'new-opera-campaign' ||
                    this.$route.name === 'new-truecaller-campaign' ||
                    this.$route.name === 'new-phoenix-campaign' ||
                    this.$route.name === 'new-transsion-campaign' ||
                    this.$route.name === 'new-ctv-campaign' ||
                    this.$route.name === 'new-gaming-campaign' ||
                    this.$route.name == 'edit-dsp-campaign'
                  "
                  class="d-flex justify-end"
                >
                  <v-btn color="primary" @click="next" block depressed>
                    {{
                      this.$route.name == "edit-dsp-campaign"
                        ? "Update Campaign"
                        : "Publish Campaign"
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>

          <!-- Frequency capping -->
          <v-card v-if="superAdmin" class="mb-5" outlined>
            <v-card-title class="py-2">
              <h5>Frequency Capping</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    label="Frequency Spec"
                    v-model="frequency_spec"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    v-model="frequency_count"
                    label="Frequency Count"
                    hide-details="auto"
                    placeholder="0"
                    persistent-placeholder
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    v-model="frequency_expire_after"
                    label="Expires After(hours)"
                    hide-details="auto"
                    placeholder="0"
                    persistent-placeholder
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Retargeting -->
          <v-card
            v-if="superAdmin"
            class="mb-5"
            outlined
            style="border: 1px solid yellowgreen !important"
          >
            <v-card-title
              class="py-1 d-flex justify-space-between align-center"
            >
              <div class="d-flex align-center">
                <h5>Retargeting</h5>
                <v-chip class="ma-2" small color="green" text-color="white">
                  Beta
                </v-chip>
              </div>

              <div class="d-flex align-center">
                <span class="mr-3 text-body-2">{{
                  retargeting == true ? "ON" : "OFF"
                }}</span>
                <v-switch
                  color="primary"
                  v-model="retargeting"
                  dense
                ></v-switch>
              </div>
            </v-card-title>
            <v-card-subtitle>
              <span
                >Use retargeting to reach people who have already interacted
                with your business</span
              >
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="retargeting_type">
                    <v-radio value="previous_campaigns">
                      <template v-slot:label>
                        <div class="d-flex flex-column">
                          <div>Retarget audience from a previous campaign</div>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="custom">
                      <template v-slot:label>
                        <div>Upload a customer list to retarget</div>
                      </template>
                    </v-radio>
                  </v-radio-group>

                  <div v-if="retargeting_type == 'previous_campaigns'">
                    <v-combobox
                      :items="all_campaigns"
                      label="Type to select campaign"
                      hint="You can select audiences from multiple campaigns"
                      persistent-hint
                      outlined
                      dense
                      chips
                      small-chips
                      multiple
                      return-object
                      item-text="name"
                      v-model="retargeting_campaigns"
                      hide-details="auto"
                      single-line
                    ></v-combobox>
                  </div>

                  <div v-if="retargeting_type == 'custom'">
                    <v-textarea
                      filled
                      name="custom_list"
                      label="Upload a customer list"
                      hint="Upload a list of customers to retarget"
                      persistent-hint
                    ></v-textarea>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- management area -->
          <v-card
            v-if="superAdmin"
            class="mb-5"
            outlined
            style="border: 1px solid orange !important"
          >
            <v-card-title class="py-2">
              <h5>Campaign Review</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row dense>
                <v-col>
                  <div class="d-flex align-center">
                    <div>
                      <h5>Banner Branding</h5>
                      <p class="text-caption">Add the Logo onto the banner</p>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="d-flex align-center">
                      <span class="mr-3 text-body-2">{{
                        banner_branding == true ? "ON" : "OFF"
                      }}</span>
                      <v-switch
                        color="primary"
                        :readonly="viewMode"
                        v-model="banner_branding"
                        dense
                      ></v-switch>
                    </div>
                  </div>

                  <v-radio-group
                    :readonly="!banner_branding"
                    v-model="branding_type"
                    row
                    dense
                    hide-details
                    class="mt-0"
                  >
                    <v-radio value="none">
                      <template v-slot:label>
                        <div class="text-caption">None</div>
                      </template>
                    </v-radio>
                    <v-radio value="pal-orange">
                      <template v-slot:label>
                        <div>
                          <v-img
                            :src="require('../../assets/pal-orange.svg')"
                            max-width="20"
                          ></v-img>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="pal-blue">
                      <template v-slot:label>
                        <div>
                          <v-img
                            :src="require('../../assets/pal-blue.svg')"
                            max-width="20"
                          ></v-img>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="mediapal">
                      <template v-slot:label>
                        <div>
                          <v-img
                            :src="require('../../assets/mediapal-blue.svg')"
                            max-width="50"
                          ></v-img>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="adchoices">
                      <template v-slot:label>
                        <div>
                          <v-img
                            :src="require('../../assets/adchoices.png')"
                            max-width="20"
                          ></v-img>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-divider class="my-3"></v-divider>

              <v-row class="flex-column" dense>
                <v-col>
                  <h5 class="mb-1">Campaign Weight</h5>
                  <v-text-field
                    v-model="weight"
                    single-line
                    label="Camapign Weight"
                    outlined
                    dense
                    type="number"
                    :rules="weightRules"
                    placeholder="1"
                    persistent-placeholder
                    hide-details="auto"
                    hint="Determines how the campaign will be prioritised by the bidder, default is 1"
                    persistent-hint
                  ></v-text-field>
                </v-col>

                <v-col>
                  <h5 class="mb-1">Bidding eCPM</h5>
                  <v-text-field
                    v-model="BeCPM"
                    single-line
                    label="Bidding eCpm"
                    outlined
                    dense
                    type="number"
                    :rules="[(v) => !!v || 'This field is required!']"
                    placeholder="$0.00"
                    persistent-placeholder
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <h5 class="mb-1">Bidding Budget</h5>
                  <v-text-field
                    v-model="bidding_budget"
                    single-line
                    outlined
                    disabled
                    dense
                    type="number"
                    placeholder="$0.00"
                    persistent-placeholder
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <h5 class="mb-1">Bidder Region</h5>
                  <v-select
                    :readonly="viewMode"
                    v-model="selected_regions"
                    :items="regions"
                    item-text="name"
                    item-value="code"
                    return-object
                    label="Choose region(s)"
                    multiple
                    chips
                    small-chips
                    outlined
                    dense
                    hide-details="auto"
                    single-line
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index < 1">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ selected_regions.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col v-if="!appCampaign">
                  <h5 class="mb-1">Rules</h5>
                  <v-select
                    :readonly="viewMode"
                    v-model="selected_rules"
                    :items="dsp_campaign_rules"
                    item-text="name"
                    item-value="id"
                    label="Please select rules"
                    multiple
                    chips
                    return-object
                    small-chips
                    outlined
                    dense
                    hide-details="auto"
                    single-line
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index < 1">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ selected_rules.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <h5 class="mb-1">Google Pretargets</h5>
                  <v-select
                    :loading="pretargetsLoading"
                    :readonly="viewMode"
                    required
                    :items="google_pretargets"
                    outlined
                    dense
                    label="Select google pretarget"
                    item-text="displayName"
                    return-object
                    v-model="selected_google_pretargets"
                    hide-details="auto"
                    single-line
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h5>
                    <strong class="success--text">Google</strong> Billing ID :
                    {{ billing_id ? billing_id : "N/A" }}
                  </h5>
                </v-col>
              </v-row>
              <v-divider class="my-5"></v-divider>
              <v-row dense>
                <v-col cols="12">
                  <h5>Running times</h5>
                </v-col>
                <v-col>
                  <v-row align="center">
                    <v-col>
                      <v-menu
                        :readonly="viewMode"
                        ref="menu"
                        v-model="starttime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="start"
                            label="Start"
                            dense
                            prepend-inner-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            placeholder="Pick Start Time"
                            persistent-placeholder
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="starttime"
                          v-model="start"
                          full-width
                          @click:minute="$refs.menu.save(time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>

                    <v-col>
                      <v-dialog
                        :readonly="viewMode"
                        ref="modal2"
                        v-model="modal2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="end"
                            label="Stop"
                            prepend-inner-icon="mdi-clock-time-eight-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            placeholder="Pick Stop Time"
                            persistent-placeholder
                          ></v-text-field>
                        </template>
                        <v-time-picker v-if="modal2" v-model="end" full-width>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            depressed
                            color="primary"
                            @click="modal2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            depressed
                            color="primary"
                            @click="$refs.modal2.save(time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>

                    <v-col>
                      <v-btn
                        depressed
                        :disabled="isDisabled({ start, end })"
                        @click="addRunningTime"
                        color="success"
                        block
                      >
                        Add
                        <v-icon right dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-list dense flat class="mt-5">
                    <v-list-item-group v-model="running_time" color="primary">
                      <v-list-item v-for="(item, i) in running_times" :key="i">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item[0] }} to {{ item[1] }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-btn
                            :disabled="viewMode"
                            @click="deleteRunningTime(i)"
                            icon
                          >
                            <v-icon color="error">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col
                  v-if="
                    this.$route.name == 'new-campaign' ||
                    this.$route.name === 'new-opera-campaign' ||
                    this.$route.name === 'new-truecaller-campaign' ||
                    this.$route.name === 'new-phoenix-campaign' ||
                    this.$route.name === 'new-transsion-campaign' ||
                    this.$route.name === 'new-ctv-campaign' ||
                    this.$route.name === 'new-gaming-campaign' ||
                    this.$route.name == 'edit-dsp-campaign'
                  "
                  class="d-flex justify-end"
                >
                  <v-btn color="primary" depressed block @click="next">
                    {{
                      this.$route.name == "edit-dsp-campaign"
                        ? "Publish Campaign"
                        : "Publish Campaign"
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- Campaign Planning -->
        <v-col cols="5">
          <v-card
            outlined
            v-if="
              this.$route.name == 'view-dsp-campaign' ||
              this.$route.name == 'edit-dsp-campaign'
            "
            class="mb-5"
          >
            <v-card-title class="py-2">
              <h5>Campaign Progress</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="mb-5">
                <div class="d-flex justify-space-between">
                  <h3 class="overline">Delivery</h3>
                  <strong>{{ Math.ceil(pacingValue) }}%</strong>
                </div>

                <v-progress-linear :value="pacingValue" rounded height="8">
                </v-progress-linear>
              </div>
              <v-card color="grey lighten-5" flat class="mb-4">
                <v-card-title class="display-1">
                  {{ campImpressions.toLocaleString() }}
                </v-card-title>
                <v-card-subtitle class="overline grey--text lighten-5">
                  Total Impressions
                </v-card-subtitle>
              </v-card>
              <v-card color="grey lighten-5" flat class="mb-4">
                <v-card-title class="display-1">
                  ${{ campaignSpend }}
                </v-card-title>
                <v-card-subtitle class="overline grey--text lighten-5">
                  Total Spend
                </v-card-subtitle>
              </v-card>
              <v-card
                v-if="!isLead && !isEngagement"
                color="grey lighten-5"
                flat
                class="mb-4"
              >
                <v-card-title class="display-1">
                  {{ campClicks.toLocaleString() }}
                </v-card-title>
                <v-card-subtitle class="overline grey--text lighten-5">
                  Total Clicks
                </v-card-subtitle>
              </v-card>

              <v-card v-if="isLead" color="grey lighten-5" flat class="mb-4">
                <v-card-title class="display-1">
                  {{ campLeads }}
                </v-card-title>
                <v-card-subtitle class="overline grey--text lighten-5">
                  Total Leads
                </v-card-subtitle>
              </v-card>
              <v-card v-if="isLead" color="grey lighten-5" flat class="mb-4">
                <v-card-title class="display-1">
                  {{ campCPL }}
                </v-card-title>
                <v-card-subtitle class="overline grey--text lighten-5">
                  Avg. CPL
                </v-card-subtitle>
              </v-card>
              <v-card
                v-if="!isLead && !isEngagement"
                color="grey lighten-5"
                flat
                class="mb-4"
              >
                <v-card-title class="display-1"> {{ campCTR }}% </v-card-title>
                <v-card-subtitle class="overline grey--text lighten-5">
                  Avg. CTR
                </v-card-subtitle>
              </v-card>
            </v-card-text>
          </v-card>
          <!-- Campaign Planning -->
          <campaign-planning v-if="this.$route.name == 'new-campaign'" />

          <!-- activity log -->
          <v-card v-if="superAdmin && viewMode" class="mb-5">
            <v-card-title class="py-2">
              <h5>Activity Log</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-timeline :reverse="false" dense>
                <v-timeline-item small v-for="item in log" :key="item.id">
                  <v-card class="elevation-2">
                    <v-card-title class="subtitle-2">
                      {{ item.description }} - {{ eventTime(item.created_at) }}
                    </v-card-title>
                    <v-card-text>
                      <P>Causer: {{ item.properties.name }}</P>
                      <p>
                        Type: {{ item.properties.oldValues.type }} to
                        {{ item.properties.newValues.type }}
                      </p>
                      <p>
                        Budget: {{ item.properties.oldValues.budget }} to
                        {{ item.properties.newValues.budget }}
                      </p>
                      <p>
                        Start Time:
                        {{ eventTime(item.properties.oldValues.activate_time) }}
                        to
                        {{ eventTime(item.properties.newValues.activate_time) }}
                      </p>
                      <p>
                        End Time:
                        {{ eventTime(item.properties.oldValues.expire_time) }}
                        to
                        {{ eventTime(item.properties.newValues.expire_time) }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog scrollable v-model="addNewTaget" persistent max-width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Add New Targeting Rule</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon dark @click="addNewTaget = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <add-new-targeting-rule
            v-if="carriers && mobile_os && phone_dataset && mobile_devices"
            @create="createTarget($event)"
            :carriers="carriers"
            :mobile_os="mobile_os"
            :phone_dataset="phone_dataset"
            :mobile_devices="mobile_devices"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CampaignPlanning from "../NewCampaign/CampaignPlanning.vue"
import { dspCampaignMixin } from "../../mixins/dspCampaignMixin"
// import AddNewTargetingRule from "../../../components/Programmatic/AddNewTargetingRule.vue";
import AddNewTargetingRule from "../Programmatic/AddNewTargetingRule.vue"

export default {
  mixins: [dspCampaignMixin],

  data() {
    return {
      loading3: false,
      selected_restricted_categories: {
        value: 0,
        name: "Ad has no restricted categories",
      },
      weightRules: [
        (v) => !!v || "This field is required!",
        (v) => (v && v > 0 && v <= 5) || "Weight must be between 1 and 5",
      ],
    }
  },
  components: {
    CampaignPlanning,
    AddNewTargetingRule,
  },
  created() {
    this.getStaticDspData()
  },
  methods: {
    async getStaticDspData() {
      try {
        await this.$store.dispatch("dsp/getStaticDspData")
      } catch (error) {
        this.error = error
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    },
  },
  computed: {
    _formattedDates() {
      return {
        start: this.formatDate(this.startDate),
        end: this.formatDate(this.endDate),
      }
    },
    bidOptions() {
      if (this.campaignObjective?.value == "awareness") {
        return [
          { name: "CPC", value: "cpc" },
          { name: "CPM", value: "cpm" },
          { name: "CPV", value: "cpv" },
          { name: "CPE", value: "cpe" },
        ]
      } else if (this.campaignObjective?.value == "reach") {
        return [
          { name: "CPC", value: "cpc" },
          { name: "CPM", value: "cpm" },
          { name: "CPV", value: "cpv" },
          { name: "CPE", value: "cpe" },
        ]
      } else if (this.campaignObjective?.value == "traffic") {
        return [{ name: "CPC", value: "cpc" }]
      } else if (this.campaignObjective?.value == "leads") {
        return [{ name: "CPL", value: "cpl" }]
      } else if (this.campaignObjective?.value == "installs") {
        return [
          { name: "CPA", value: "cpa" },
          { name: "CPI", value: "cpi" },
        ]
      } else if (this.campaignObjective?.value == "sales") {
        return [{ name: "CPA", value: "cpa" }]
      } else if (this.campaignObjective?.value == "product_consideration") {
        return [{ name: "CPV", value: "cpv" }]
      } else if (this.campaignObjective?.value == "conversion") {
        return [{ name: "CPA", value: "cpa" }]
      } else if (this.campaignObjective?.value == "store_visits") {
        return [{ name: "CPM", value: "cpm" }]
      } else {
        return []
      }
    },
    computedTypes() {
      return this.campaignObjective?.campaign_types ?? []
    },
    countries() {
      return this.$store.state.dsp.static_dsp_data.countries
    },
    restricted_categories() {
      return this.$store.state.dsp.static_dsp_data.restricted_categories
        .restricted_categories
    },
    pacingValue() {
      let value = (this.campImpressions / this.impressions) * 100
      if (!this.superAdmin && value > 100) {
        value = 100
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0
      }
      return value
    },
    campaignSpend() {
      let budget = this.totalBudget
      if (budget == null) {
        budget = 0
      }
      let value = this.campSpend
      if (!this.superAdmin && value > budget) {
        value = budget
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0
      }
      return value
    },
    campaignImpressions() {
      let value = this.campImpressions
      if (!this.superAdmin && this.campImpressions > this.impressions) {
        value = this.impressions
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0
      }
      return value
    },
    //campLeads
    // let cpl = item.cpl ? item.cpl.toFixed(2) : 0;
    //     if (!this.superAdmin){
    //       cpl = (this.spend(item)/this.checkLeads(item)).toFixed(2);
    //     }
    campaignCPL() {
      let value = this.campSpend / this.campLeads
      if (!this.superAdmin && this.campSpend > this.campaignSpend) {
        value = this.campaignSpend / this.campLeads
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0
      }
      return value
    },
    carriers() {
      return this.$store.state.dsp.static_dsp_data.carriers
    },
    mobile_os() {
      return this.$store.state.dsp.static_dsp_data.mobile_os
    },
    mobile_devices() {
      return this.$store.state.dsp.static_dsp_data.mobile_devices
    },
    phone_dataset() {
      return this.$store.state.dsp.static_dsp_data.phone_dataset
    },
    dsp_targets() {
      return this.$store.state.dsp.dsp_targets
    },
    single_target() {
      return this.$store.state.dsp.single_target
    },
    minDate() {
      if (this.superAdmin) {
        return ""
      } else {
        let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
        return date
      }
    },
  },
  watch: {
    pacing_value() {
      this.pacingValue()
    },
  },
}
</script>
